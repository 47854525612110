/* FANCY HOVER */
.btn {
  position: relative;
  color: var(--dark);
  width: 256px;
  height: 64px;
  /* line-height: 64px; */
  transition: all 0.3s;
  /* span: {
		transition: all 0.3s;
		tranform: scale(1, 1);
	} */
}

.btn::before,
.btn::after {
  content: '';
  position: absolute;
  transition: all 0.3s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.home-product-box-container {
  justify-content: space-between;
  color: var(--product-box-color) !important;

  margin: auto;
}

.home-product-box {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 230px;
  min-height: 200px;
  text-align: center;
  cursor: pointer;
  background-color: var(--product-box-color) !important;
  /* background-color: white !important; */
  border-radius: 3px;
  border: 0.5px solid;
  border-color: var(--bar-bg-color-medium1);
}

.home-product-box:hover {
  box-shadow: var(--bar-bg-color-dark2) 0px 0px 2px;
}

.home-product-box:hover .home-product-box-product-text {
  display: none;
}

.home-product-box:hover .home-product-box-desc-text {
  display: inline;
}

.home-product-box:hover .home-product-box-arrow-text {
  display: inline;
}

.home-product-box:hover .home-product-box-product-icon {
  font-size: 30px;
}

.home-product-box-sub {
  position: absolute;
  width: 100%;
  height: 100%;
  top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-product-box-product-icon {
  font-size: 32px;
  margin-top: 10px;
  font-family: 'comfortaa';
  color: var(--bar-bg-color-dark1);
  /* font-weight: 600; */
}

.home-product-box-product-text {
  font-size: 30px;
  margin-bottom: auto;
  margin-top: auto;
  font-family: 'Poppins', sans-serif;

  /* padding: 10px; */
  /* font-weight: 600; */
}

.home-product-box-desc-text {
  font-size: 14px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  display: none;
  font-weight: 300;
}

.home-product-box-arrow {
  margin-top: auto;
  /* color: var(--product-box-border-color) !important;
  border-color: var(--product-box-border-color) !important; */
}

.home-product-box-arrow-text {
  display: none;
}

.ant-btn {
  border: 0 !important;
}

@media (max-width: 1100px) {
  .home-product-box-container {
    /* margin-left: 10px;
    margin-right: 10px; */
    /* margin-top: -5px; */
    margin-bottom: 0px;
    flex-wrap: wrap;
  }

  .home-product-box {
    /* margin: 10px; */
  }
}

@media (max-width: 1000px) {
  .home-product-box-container {
    /* margin-left: 10px;
    margin-right: 10px; */
    /* margin-top: -5px; */
    margin-bottom: 0px;
    flex-wrap: wrap;
  }

  .home-product-box {
    margin-left: 0vw;
    margin-right: 0vw;
    /* margin: 10px; */
  }
}
/* Portrait and Landscape */
@media (max-width: 1020px) {
  .home-product-box-container {
    margin: 10px;
  }

  /* .home-product-box {
    min-width: calc(50vw - 25px);
	margin: 3px;
	min-height: 130px;
  } */

  .home-product-box {
    min-width: 49%;
    max-width: 49%;
    min-height: 155px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
  }

  .home-product-box-product-icon {
    margin-top: 15px;
    min-height: 50px;
    /* font-size: 24px; */
  }

  .home-product-box-product-text {
    font-size: 18px;
    padding: 5px;
  }

  .home-product-box-desc-text {
    visibility: collapse;
    height: 0px;
  }

  .home-product-box-arrow {
    visibility: collapse;
    height: 0px;
  }

  .home-product-box:hover .home-product-box-product-text {
    display: unset;
  }
}

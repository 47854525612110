.social-footer-container {
  display: flex;
  flex-direction: row;
}

.social-footer-header {
  margin-left: auto;
  font-family: 'Comfortaa', cursive;
  font-size: 24px;
  margin-right: 10px;
  color: var(--social-footer-color) !important;
}

.social-footer-icon {
  font-size: 32px;
  margin-right: 5px;
  cursor: pointer;
}

/* Portrait and Landscape */
@media (max-width: 700px) {
  .social-footer-header {
    margin-left: 0px;
  }

  .social-footer-container {
    justify-content: center;
  }
}

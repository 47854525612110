.why-us-icon {
  color: var(--main-color);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: 'Poppins', sans-serif;
}

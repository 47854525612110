@import './style/variables.css';

@import './style/social-footer.css';
@import './style/product-box.css';
@import './style/title-bar.css';
@import './style/product.css';
@import './style/colored-bar.css';

@import './style/home.css';

body {
  color: rgb(8, 33, 82);
  line-height: 1.5715;
  font-size: 14px;
  margin: 0;
}

div {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

a {
  text-decoration: none;
  color: #00a896;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
}

@keyframes fadeInLeft {
  from {
    transform: translateX(-60px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

.fadeInLeft {
  opacity: 0;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
}

/*
https://coolors.co/05668d-028090-00a896-02c39a-f0f3bd
https://coolors.co/05668d-028090-00a896-02c39a-f0f3bd
*/
:root {
  /* also -- color of avatar circle, whyus-icons */
  /* --main-color-text: #2d3634; */
  --main-color-text: #3f3e3e;
  /* --main-color: rgb(247, 249, 252); */
  --main-color: #ffffff;
  --main-color-pop: #f0f3bd;
  --dark: #353535;

  --masthead-title-color: var(--bar-bg-color-dark2);
  --masthead-bg-color: var(--main-color);
  --masthead-color: var(--main-color-text);

  --star-bullet-color: var(--main-color);

  /* SLANTED BARS */
  --bar-bg-color-none: #fff;
  /* --bar-bg-color-dark1: #b10000; */
  /* --bar-bg-color-dark1: #00b3ca; */
  --bar-bg-color-dark1: #00a896;
  --bar-bg-color-dark2: #00a896;
  --bar-bg-color-medium1: #00a896cc;
  --bar-bg-color-medium2: #9aacb8;
  --bar-bg-color-light1: #f7f6fa;
  --bar-bg-color-light2: rgba(61, 90, 128, 0.08);

  --bar-color-none: rgba(0, 0, 0, 0.75);
  --bar-color-dark1: #fff;
  --bar-color-dark2: #fff;
  --bar-color-medium1: #fff;
  --bar-color-medium2: #fff;
  --bar-color-light1: rgba(0, 0, 0, 0.75);
  --bar-color-light2: rgba(0, 0, 0, 0.75);

  /* PRODUCT BOXES */
  --product-box-color: var(--bar-bg-color-light1);
  --product-box-bg-color: var(--dark);
  --product-box-border-color: var(--main-color-pop);

  /* FOOTER */
  --footer-bg-color: var(--dark);
  --footer-color: #efeef3;
  --footer-link-color: #f0f3bd;
  --footer-link-hover: var(--main-color-pop);
  --social-footer-bg-color: #d60a4c;
  --social-footer-color: #fff;

  /* COOKIE */
  --cookie-bg-color: var(--dark);
  --cookie-color: #fff;
}
